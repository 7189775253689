<template>
    <div class="cusModal" v-if="showtable">
        <div class="cusModalCont">
            <div class="cusModalTitle backBlack">
                {{ title }}

                <div class="eportBtn">
                    <div>تصدير PDF</div>
                    <div>تصدير Excel</div>
                    <div>طباعة</div>
                </div>
                <div class="cusModalClose" @click="closeTable()">{{ lang.close }}</div>
            </div>
            <div class="cusModalBody">
                <v-simple-table class="mt-1" :style="`padding-inline:40px;direction: ${lang.dir};`">
                    <thead>
                        <tr>
                            <th class="backBlack text-center">المعرف</th>
                            <th class="backBlack text-center">نوع العميل</th>
                            <th class="backBlack text-center">اسم العميل</th>
                            <th class="backBlack text-center">رقم الاتصال</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in tbrows" :key="index">
                            <td class="text-center">{{item.id}}</td>
                            <td class="text-center">{{item._customer_type}}</td>
                            <td class="text-center">{{item.customer_name}}</td>
                            <td class="text-center">{{item.mobile}}</td>
                        </tr>
                    </tbody>
                    
                </v-simple-table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            showtable: false,
            title: '',
            tbrows: [],
            search: {
                sdate: '',
                edate: '',
            },
            source_id: 0,
            account_name: ''
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function () {
            let headers = [
                {
                text: this.lang.customerid,
                align: "center",
                filterable: true,
                value: "customerid",
                sortable: false,
                class: "backBlack text-center",
                },
                {
                text: this.lang.customer_type,
                align: "center",
                filterable: true,
                value: "customer_type",
                sortable: false,
                class: "backBlack text-center",
                },
                {
                text: this.lang.client_source,
                align: "center",
                filterable: true,
                value: "mobile",
                sortable: false,
                class: "backBlack text-center",
                },
                {
                text: this.lang.mobile,
                align: "center",
                filterable: true,
                value: "mobile",
                sortable: false,
                class: "backBlack text-center",
                },

                {
                text: this.lang.customer_name,
                align: "center",
                filterable: true,
                value: "full_name",
                sortable: true,
                class: "backBlack text-center",
                },
                
                {
                text: this.lang.company_name,
                align: "center",
                filterable: true,
                value: "company_name",
                sortable: false,
                class: "backBlack text-center",
                },

                {
                    text: this.lang.quotations, align: 'center',
                    filterable: true, value: 'quotations',
                    sortable: false,
                    class: 'backBlack text-center'
                }
            ]
            const sMenu = JSON.parse(localStorage.getItem("_ECar_"));
            if(sMenu.showPeriodMaint){
                headers.push({
                    text: this.lang.period_maint, 
                    align: 'center',
                    filterable: true, 
                    value: 'period_maint',
                    sortable: false,
                    class: 'backBlack text-center'
                })
            }
            if(sMenu.showSubscriber){
                headers.push({
                    text: this.lang.maint_prog, 
                    align: 'center',
                    filterable: true, 
                    value: 'maint_prog',
                    sortable: false,
                    class: 'backBlack text-center'
                })
            }
            if(sMenu.showBooking){
                headers.push({
                    text: this.lang.calendar, align: 'center',
                    filterable: true, value: 'calendars',
                    sortable: false,
                    class: 'backBlack text-center'
                })
            }
            if(sMenu.showMintContract){
                headers.push({
                    text: this.lang.maint_contract, align: 'center',
                    filterable: true, value: 'maint_contract',
                    sortable: false,
                    class: 'backBlack text-center'
                })
            }
            return  headers;
        }
            
    },
    methods: {
        closeTable(){
            this.showtable = false;
            this.tbrows = []
            this.search = {
                sdate: '',
                edate: '',
            }
            this.classid = 0
            this.account_name = ''
        },
        getReport(item) {
            this.showtable = true;
            // console.log(item);
            this.title = item[`name${this.lang.langname}`]
            const post = new FormData();
            post.append("type", "getClientsDashboard");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[options]", JSON.stringify(item));
            
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.tbrows = response.data.results.data;
            });
        }
    },
    created() {
        //
    },
}
</script>
<style scoped>
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
.eportBtn{
    position: absolute;
    top:2px;
    left:60px;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
    display:flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}
.eportBtn div{
    padding:2px 5px;
    width:80px;
    font-size:.8rem;
    background:blue;
    margin-inline-start:5px;
    color:#fff;
    border-radius: 3px;
}
</style>

