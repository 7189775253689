<template>
    <div class="cusModal" v-if="showtable">
        <div class="cusModalCont">
            <div class="cusModalTitle backBlack">طلبات التعميد
                <div class="eportBtn">
                    <div>تصدير PDF</div>
                    <div>تصدير Excel</div>
                    <div>طباعة</div>
                </div>
                <div class="cusModalClose" @click="closeTable()">{{ lang.close }}</div>
            </div>
            <div class="cusModalBody">
                <v-simple-table class="mt-1" :style="`padding-inline:40px;direction: ${lang.dir};`">
                    <thead>
                        <tr>
                            <th class="backBlack text-center">تاريخ الارسال</th>
                            <th class="backBlack text-center">المرسل</th>
                            <th class="backBlack text-center">نوع التعميد</th>
                            <th class="backBlack text-center" style="width:300px;">الملاحظات</th>
                            
                            <th class="blackBack text-center" style="width:240px;">{{ lang.action }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in tbrows" :key="index">
                            <td>{{item.sent_date}}</td>
                            <td>{{item._sent_by}}</td>
                            <td>{{item._doc_type}}</td>
                            <td style="width:300px;"><b-form-input class="inborder" v-model="tbrows[index].approve_notes" />
                                <div style="display:none;color:red" :id="`showErMessage_${item.id}`"><small>يرجى اضافة الملاحظة</small></div>
                            </td>

                            <td>
                                <div style="display:flex;justify-content:space-between;">
                                    <div style="cursor:pointer;width:80px;padding:2px;margin-inline-end:5px;text-align:center;background:blue;color:#fff;" @click="Apr(index,4)">تأجيل</div>
                                    <div style="cursor:pointer;width:80px;padding:2px;margin-inline-end:5px;text-align:center;background:red;color:#fff;" @click="Apr(index,2)">رفض</div>
                                    <div style="cursor:pointer;width:80px;padding:2px;margin-inline-end:5px;text-align:center;background:green;color:#fff;" @click="Apr(index,2)">تعميد</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            showtable: false,
            tbrows: [],
            search: {
                sdate: '',
                edate: '',
            },
            showErMessage: false
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        totals: function(){
            let t = {
                total: 0, qty: 0, current: 0
            }
            for(let i=0;i<this.tbrows.length;i++){
                t.qty = parseFloat(t.qty) + parseFloat(this.tbrows[i].qtty)
                t.total = parseFloat(t.total) + parseFloat(this.tbrows[i].total)
                t.current = parseFloat(t.current) + parseFloat(this.tbrows[i].current)
            }
            return t;
        },
    },
    methods: {
        Apr(index,status){
            const post = new FormData();
            post.append('type','doApprove');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.tbrows[index].id);
            post.append('data[status]',status);
            post.append('data[approve_notes]',this.tbrows[index].approve_notes);
            console.log(this.tbrows[index]);
            if(this.tbrows[index].approve_notes == ''){
                // console.log(this.tbrows[index])
                document.getElementById(`showErMessage_`+this.tbrows[index].id).style.display='block';
                return false;
            }
            this.tbrows = [];
            
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                this.getReport();
                
            })
        },
        getReport(){
            const post = new FormData();
            post.append('type','getApproved');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',0);
           
            this.tbrows = [];
            this.showtable = true;
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                this.tbrows = response.data.results.data;
            })
        },
        closeTable(){
            this.showtable = false;
            this.tbrows = []
            this.search = {
                sdate: '',
                edate: '',
            }
        },
        
    },
    created() {
        //
    },
}
</script>
<style scoped>
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
.eportBtn{
    position: absolute;
    top:2px;
    left:60px;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
    display:flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}
.eportBtn div{
    padding:2px 5px;
    width:80px;
    font-size:.8rem;
    background:blue;
    margin-inline-start:5px;
    color:#fff;
    border-radius: 3px;
}
</style>

